/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum MarketplaceOperator {
    PorscheMP = 'PorscheMP',
    PorscheMP_Canada = 'PorscheMP_Canada',
    PorscheMP_USA = 'PorscheMP_USA',
    PorscheMP_AU = 'PorscheMP_AU',
    PorscheMP_GBP = 'PorscheMP_GBP',
    PorscheMP_CHF = 'PorscheMP_CHF',
}

export function instanceOfMarketplaceOperator(value: any): boolean {
    return Object.values(MarketplaceOperator).includes(value)
}

export function MarketplaceOperatorFromJSON(json: any): MarketplaceOperator {
    return MarketplaceOperatorFromJSONTyped(json, false)
}

export function MarketplaceOperatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketplaceOperator {
    return json as MarketplaceOperator
}

export function MarketplaceOperatorToJSON(value?: MarketplaceOperator | null): any {
    return value as any
}
