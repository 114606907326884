import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { PHeading, PLinkPure, PText, useToastManager } from '@porsche-design-system/components-react'
import { spacingStatic } from '@porsche-design-system/components-react/styles'
import { Flex, FlexItem } from '@slcheckout/ui-kit'

import { StaticRoutes } from '@slmpserv/self-service-static-routes'
import { BoxLayout, PageLayout } from '@slmpserv/common-components-legacy'
import { MultipleFormSubmitProvider } from '@slmpserv/common-contexts'
import { createMerchantMessages, routesMessages, updateMerchantMessages } from '@slmpserv/common-translations'
import { ImprintSelfService } from '@slmpserv/common/imprint-form'

export function ImprintPage() {
    const { formatMessage } = useIntl()
    const { addMessage } = useToastManager()

    return (
        <PageLayout
            header={
                <>
                    <Link to={StaticRoutes.DASHBOARD}>
                        <PLinkPure icon={'arrow-head-left'} style={{ marginBottom: spacingStatic.large }}>
                            {formatMessage(routesMessages.backToDashboard)}
                        </PLinkPure>
                    </Link>
                    <Flex direction="column">
                        <FlexItem>
                            <PHeading tag="h3" size="large">
                                {formatMessage(routesMessages.imprintHeadline)}
                            </PHeading>
                        </FlexItem>
                        <FlexItem>
                            <PText color={'neutral-contrast-medium'} style={{ whiteSpace: 'break-spaces' }}>
                                {formatMessage(routesMessages.imprintSubHeadline)}
                            </PText>
                        </FlexItem>
                    </Flex>
                </>
            }
        >
            <BoxLayout>
                <MultipleFormSubmitProvider
                    onError={() =>
                        addMessage({ state: 'neutral', text: formatMessage(updateMerchantMessages.saveError) })
                    }
                    onSuccess={() =>
                        addMessage({ state: 'success', text: formatMessage(createMerchantMessages.saveSuccess) })
                    }
                >
                    <ImprintSelfService />
                </MultipleFormSubmitProvider>
            </BoxLayout>
        </PageLayout>
    )
}
