/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum DownPaymentConfigurationStatus {
    ACTIVE = 'ACTIVE',
    OPT_IN_INACTIVE = 'OPT_IN_INACTIVE',
    DOWN_PAYMENT_VALUE_MISSING = 'DOWN_PAYMENT_VALUE_MISSING',
}

export function instanceOfDownPaymentConfigurationStatus(value: any): boolean {
    return Object.values(DownPaymentConfigurationStatus).includes(value)
}

export function DownPaymentConfigurationStatusFromJSON(json: any): DownPaymentConfigurationStatus {
    return DownPaymentConfigurationStatusFromJSONTyped(json, false)
}

export function DownPaymentConfigurationStatusFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): DownPaymentConfigurationStatus {
    return json as DownPaymentConfigurationStatus
}

export function DownPaymentConfigurationStatusToJSON(value?: DownPaymentConfigurationStatus | null): any {
    return value as any
}
