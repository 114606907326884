/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum MarketplaceCurrency {
    AUD = 'AUD',
    AMD = 'AMD',
    AZN = 'AZN',
    BAM = 'BAM',
    BGN = 'BGN',
    BRL = 'BRL',
    BYN = 'BYN',
    CAD = 'CAD',
    CHF = 'CHF',
    CZK = 'CZK',
    EUR = 'EUR',
    GEL = 'GEL',
    GBP = 'GBP',
    HRK = 'HRK',
    HUF = 'HUF',
    ILS = 'ILS',
    KZT = 'KZT',
    JPY = 'JPY',
    KRW = 'KRW',
    MDL = 'MDL',
    MKD = 'MKD',
    MXN = 'MXN',
    NOK = 'NOK',
    PLN = 'PLN',
    RON = 'RON',
    SEK = 'SEK',
    SGD = 'SGD',
    RSD = 'RSD',
    TRY = 'TRY',
    TWD = 'TWD',
    UAH = 'UAH',
    USD = 'USD',
    UZS = 'UZS',
}

export function instanceOfMarketplaceCurrency(value: any): boolean {
    return Object.values(MarketplaceCurrency).includes(value)
}

export function MarketplaceCurrencyFromJSON(json: any): MarketplaceCurrency {
    return MarketplaceCurrencyFromJSONTyped(json, false)
}

export function MarketplaceCurrencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketplaceCurrency {
    return json as MarketplaceCurrency
}

export function MarketplaceCurrencyToJSON(value?: MarketplaceCurrency | null): any {
    return value as any
}
