/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum MarketplaceLegalDocumentType {
    TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
    PRIVACY_POLICY = 'PRIVACY_POLICY',
}

export function instanceOfMarketplaceLegalDocumentType(value: any): boolean {
    return Object.values(MarketplaceLegalDocumentType).includes(value)
}

export function MarketplaceLegalDocumentTypeFromJSON(json: any): MarketplaceLegalDocumentType {
    return MarketplaceLegalDocumentTypeFromJSONTyped(json, false)
}

export function MarketplaceLegalDocumentTypeFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean
): MarketplaceLegalDocumentType {
    return json as MarketplaceLegalDocumentType
}

export function MarketplaceLegalDocumentTypeToJSON(value?: MarketplaceLegalDocumentType | null): any {
    return value as any
}
