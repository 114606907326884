import { useIntl } from 'react-intl'
import { Navigate, Route, Routes } from 'react-router-dom'

import { ApiError } from '@slmpserv/common-api-merchant'
import { LoadingBoundary } from '@slmpserv/common-components-legacy'
import { MarketplaceContextProvider, MerchantContextProvider } from '@slmpserv/common-contexts'
import { routesMessages } from '@slmpserv/common-translations'
import { useFetchMarketplace, useFetchMerchant } from '@slmpserv/common/api/use-query-wrapper'
import { useMarketplaceFeatures } from '@slmpserv/hooks'
import { useCurrentDealerContext } from '@slmpserv/self-service-current-dealer-context'
import { StaticRoutes } from '@slmpserv/self-service-static-routes'

import {
    ContactPage,
    DashboardPage,
    DocumentManagementPage,
    DownPaymentPage,
    ImprintPage,
    KycPage,
    PayoutContactPage,
    PrivacyPolicyDocuments,
    SellerContactEmailPage,
    SellerContactPhonePage,
    TermsAndConditionsDocuments,
} from '../../pages'
import { ErrorRedirectBuilder } from '../../pages/Error/ErrorRedirectBuilder'
import { KycNotificationsPage } from '../../pages/KycNotificationsPage'
import { PurchaseRequestContactPage } from '../../pages/PurchaseRequestContactPage'

export function DashboardRoutes() {
    const { formatMessage } = useIntl()
    const { partnerNumber, dealershipId } = useCurrentDealerContext()
    const {
        merchant,
        queryResult: { isLoading: isLoadingMerchant, isError: isMerchantFetchError, error },
    } = useFetchMerchant({
        ppnId: partnerNumber,
    })
    const {
        marketplace,
        queryResult: { isLoading: isLoadingMarketplace, isError: isMarketplaceError, error: marketplaceError },
    } = useFetchMarketplace({
        marketplaceKey: merchant?.marketplaceKey ?? '',
    })
    const featureFlags = useMarketplaceFeatures({ marketplace })

    if (isMerchantFetchError) {
        return <Navigate {...ErrorRedirectBuilder.handleMerchantError(partnerNumber, error as unknown as ApiError)} />
    }
    if (isMarketplaceError) {
        return <Navigate {...ErrorRedirectBuilder.handleMarketplaceError(marketplaceError as unknown as ApiError)} />
    }

    if (isLoadingMerchant || isLoadingMarketplace) {
        return <LoadingBoundary isLoading={true} />
    }

    return (
        <MerchantContextProvider
            ppnId={partnerNumber}
            errorFallback={
                <Navigate {...ErrorRedirectBuilder.handleMerchantError(partnerNumber, error as unknown as ApiError)} />
            }
        >
            <MarketplaceContextProvider marketplaceKey={merchant.marketplaceKey}>
                <Routes>
                    <Route
                        path={StaticRoutes.CONTACT}
                        element={
                            featureFlags.ONLINE_ORDER_CONFIG ? (
                                <ContactPage />
                            ) : (
                                <Navigate {...ErrorRedirectBuilder.featureNotEnabled()} />
                            )
                        }
                    />
                    <Route
                        path={StaticRoutes.SELLER_CONTACT_EMAIL}
                        element={<SellerContactEmailPage entryDn={dealershipId} />}
                    />
                    <Route
                        path={StaticRoutes.SELLER_CONTACT_PHONE}
                        element={<SellerContactPhonePage entryDn={dealershipId} />}
                    />
                    <Route
                        path={StaticRoutes.PURCHASE_REQUEST_CONTACT}
                        element={
                            featureFlags.PURCHASE_REQUEST_SETTINGS ? (
                                <PurchaseRequestContactPage />
                            ) : (
                                <Navigate {...ErrorRedirectBuilder.featureNotEnabled()} />
                            )
                        }
                    />
                    <Route
                        path={StaticRoutes.PAYOUT_CONTACT}
                        element={
                            featureFlags.PAYOUT_SECTION ? (
                                <PayoutContactPage />
                            ) : (
                                <Navigate {...ErrorRedirectBuilder.featureNotEnabled()} />
                            )
                        }
                    />
                    <Route
                        path={StaticRoutes.KYC_NOTIFICATIONS}
                        element={
                            featureFlags.KYC_MANAGEMENT ? (
                                <KycNotificationsPage />
                            ) : (
                                <Navigate {...ErrorRedirectBuilder.featureNotEnabled()} />
                            )
                        }
                    />
                    <Route
                        path={StaticRoutes.KYC_MANAGEMENT}
                        element={
                            featureFlags.KYC_MANAGEMENT ? (
                                <KycPage />
                            ) : (
                                <Navigate {...ErrorRedirectBuilder.featureNotEnabled()} />
                            )
                        }
                    />
                    <Route
                        path={StaticRoutes.DOCUMENT_MANAGEMENT_TERMS_AND_CONDITIONS}
                        element={
                            featureFlags.DOCUMENT_MANAGEMENT ? (
                                <DocumentManagementPage
                                    title={formatMessage(routesMessages.documentManagementHeadline)}
                                    description={formatMessage(routesMessages.documentManagementSubHeadline)}
                                    children={<TermsAndConditionsDocuments />}
                                />
                            ) : (
                                <Navigate {...ErrorRedirectBuilder.featureNotEnabled()} />
                            )
                        }
                    />
                    <Route
                        path={StaticRoutes.DOCUMENT_MANAGEMENT_PRIVACY_POLICY}
                        element={
                            featureFlags.DOCUMENT_MANAGEMENT ? (
                                <DocumentManagementPage
                                    title={formatMessage(routesMessages.privacyPolicyHeadline)}
                                    description={formatMessage(routesMessages.privacyPolicySubHeadline)}
                                    children={<PrivacyPolicyDocuments />}
                                />
                            ) : (
                                <Navigate {...ErrorRedirectBuilder.featureNotEnabled()} />
                            )
                        }
                    />
                    <Route
                        path={StaticRoutes.DOCUMENT_MANAGEMENT_IMPRINT}
                        element={
                            featureFlags.DOCUMENT_MANAGEMENT ? (
                                <ImprintPage />
                            ) : (
                                <Navigate {...ErrorRedirectBuilder.featureNotEnabled()} />
                            )
                        }
                    />
                    <Route
                        path={StaticRoutes.ONLINE_ORDER_CONFIGURATION}
                        element={
                            featureFlags.ONLINE_ORDER_CONFIG ? (
                                <DownPaymentPage />
                            ) : (
                                <Navigate {...ErrorRedirectBuilder.featureNotEnabled()} />
                            )
                        }
                    />
                    <Route path={StaticRoutes.DASHBOARD} element={<DashboardPage />} />
                    <Route path={'*'} element={<Navigate to={StaticRoutes.DASHBOARD} />} />
                </Routes>
            </MarketplaceContextProvider>
        </MerchantContextProvider>
    )
}
